import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PagePrincipal } from "./pages/principal";
import { PageCarrinho } from "./pages/carrinho";
import { PageProduto } from "./pages/produto";
import { PageSobre } from "./pages/sobre";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import { PainelLogin } from "./pages/painel/login";
import { PainelPrincipal } from "./pages/painel/principal";
import Mapa from "./componentes/Mapa/Mapa";
import "react-toastify/dist/ReactToastify.css";
import { PagePedido } from "./pages/pedido";
import { PageDetalhePedido } from "./pages/detalhe";
import { TermoPrivacidade } from "./pages/termo-e-privacidade";
import { PageFideldiade } from "./pages/fidelidade";
import { PageRecovery } from "./pages/recovery";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/empresa/painel" element={<PainelPrincipal />} />

        <Route path="/" element={<PagePrincipal />} />
        <Route path="/:empresa" element={<PagePrincipal />} />
        <Route path="/:empresa/recovery/:celular" element={<PageRecovery />} />
        <Route path="/recovery/:celular" element={<PageRecovery />} />

        <Route path="/carrinho" element={<PageCarrinho />} />
        <Route path="/:empresa/carrinho" element={<PageCarrinho />} />

        <Route path="/pedido" element={<PagePedido />} />
        <Route path="/:empresa/pedido" element={<PagePedido />} />

        <Route path="/pedido/detalhe/:codigo" element={<PageDetalhePedido />} />
        <Route
          path="/:empresa/pedido/detalhe/:codigo"
          element={<PageDetalhePedido />}
        />

        <Route path="/produto/:product" element={<PageProduto />} />
        <Route path="/:empresa/produto/:product" element={<PageProduto />} />

        <Route path="/sobre" element={<PageSobre />} />
        <Route path="/:empresa/sobre" element={<PageSobre />} />

        <Route path="/fidelidade" element={<PageFideldiade />} />
        <Route path="/:empresa/fidelidade" element={<PageFideldiade />} />

        <Route
          path="/:empresa/termo-e-politica"
          element={<TermoPrivacidade />}
        />
        <Route path="/termo-e-politica" element={<TermoPrivacidade />} />

        {/* 
        <Route path="/:empresa" element={<Home edit_imagem={false} />} />
        <Route path="/loja-altera-imagem/:empresa" element={<Home edit_imagem={true} />} />
        <Route path="/:empresa/:pedido" element={<ResumoPedido />} />
        <Route path="/:empresa/mesa/:codigo" element={<Home edit_imagem={false} />} />
        <Route path="/:empresa/desc/:cupom" element={<Home edit_imagem={false} />} />
        <Route path="/desc/:cupom" element={<Home edit_imagem={false} />} />
        <Route path="/partner/:partner" element={<Home edit_imagem={false} />} />

        <Route path="/mesa/:codigo" element={<Home edit_imagem={false} />} />
        <Route path="/U0U5S1JTQkRUMDFGVWtWTlQxTWdRMVU9" element={<HojeComeremos />} />
        <Route path="/landpage" element={<LandPage />} />
        <Route path="/admin" element={<HomeGooPedir />} />
        <Route path="/admin/dados/pix" element={<PageDadosPIX />} />
        <Route path="/motoboy" element={<PageMotoboy />} />


        
      <Route path="/fidelidade" element={<PageFidelidade />} />
      <Route path="/:empresa/fidelidade" element={<PageFidelidade />} />

      <Route path="/termo-e-politica" element={<PageTermoPolitica />} />

      <Route path="*" element={
        process.env.REACT_APP_EMPRESA === undefined ? <LandPage /> : <Home edit_imagem={false} />} /> */}
      </Routes>
    </BrowserRouter>
  </>
);
